export const speiseplan = {    
    periode:  "Aktuelle Einschränkungen",
    
    zusatz:  "wegen Personalmangel",

    montag: {
        sichtbar: true,
        gericht: "Hausgemachte Tagessuppe",
        beigabe: "",
        preis: "7,00 €"
    },

    dienstag: {
        sichtbar: true,
        gericht: "Heiße Würstchen",
        beigabe: "mit Kartoffelsalat",
        preis: "7,90"
    },


    mittwoch: {
        sichtbar: true,
        gericht: "Heiße Würstchen",
        beigabe: "mit Kartoffelsalat",
        preis: "7,90 €"
    },

    donnerstag: {
        sichtbar: true,
        gericht: "Heiße Würstchen",
        beigabe: "mit Kartoffelsalat",
        preis: "7,90"
    },

    freitag: {
        sichtbar: true,
        gericht: "Hausgemachte Tagessuppe",
        beigabe: "",
        preis: "7,00 €"
    },
};
